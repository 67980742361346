<template>
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.33325 6.5C4.33325 5.11929 5.45254 4 6.83325 4H22.8333C24.214 4 25.3333 5.11929 25.3333 6.5V22.5C25.3333 23.8807 24.214 25 22.8333 25H6.83325C5.45254 25 4.33325 23.8807 4.33325 22.5V6.5ZM6.83325 5C6.00482 5 5.33325 5.67157 5.33325 6.5V22.5C5.33325 23.3284 6.00483 24 6.83325 24H14.8333L14.8333 5H6.83325ZM22.8333 24H15.8333V15.5H24.3333L24.3333 22.5C24.3333 23.3284 23.6617 24 22.8333 24ZM24.3333 14.5L24.3333 6.5C24.3333 5.67157 23.6617 5 22.8333 5H15.8333V14.5H24.3333Z"
            :fill="color"
            fill-opacity="0.6"
        />
    </svg>
</template>

<script>
export default {
    name: "BoardsIcon",
    props: {
        color: {
            type: String,
            default: "#EBEBF5"
        }
    }
};
</script>
