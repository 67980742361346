<template>
    <div v-if="userData.id" class="web-app-profile">
        <edit-profile-modal v-if="isLoggedIn" @saved-user-profile="(newUserData) => userData = newUserData" />
        <transition name="slide-top">
            <following-list
                v-show="showFollowingList"
                :user-id="Number(userData.id)"
                @hide-user-following="toggleFollowingList(false)"
            />
        </transition>
        <transition name="slide-top">
            <followers-list
                v-show="showFollowersList"
                :user-id="Number(userData.id)"
                @hide-user-followers="toggleFollowersList(false)"
            />
        </transition>

        <side-bar v-if="!isLoggedIn" />

        <profile-box
            :user-data="userData"
            @toggled-follow="(is_following) => userData.is_following = is_following"
            @toggle-block-user="(newVal) => userData.is_blocked = newVal"
            @show-user-following="toggleFollowingList(true)"
            @show-user-followers="toggleFollowersList(true)"
        />
        <memod-list-tab-bar
            v-if="!userData.profile_blocked"
            :current-list="this.$route.name"
            :tabs-list="profileTabBarList"
            is-subcategories
        />
        <div class="container">
            <template v-if="!userData.profile_blocked">
                <keep-alive>
                    <router-view :user-data="userData" :list-endpoint="currentListTab.endpoint" />
                </keep-alive>
            </template>
            <div v-else class="blocked-user">
                <img src="@assets/img/icons/blocked-users.svg" alt="Blocked Accounts">
                <h1>You're blocked</h1>
                <p>You can't follow or see<br>@{{ userData.displayname }}'s content</p>
            </div>
        </div>
    </div>
</template>

<script>
import _truncate from "lodash/truncate";
import { mapState, mapGetters } from "vuex";
import memosIcon from "@/components/atoms/profile-icons/memos.vue";
import draftsIcon from "@/components/atoms/profile-icons/drafts.vue";
import boardsIcon from "@/components/atoms/profile-icons/boards.vue";
import likedIcon from "@/components/atoms/profile-icons/liked.vue";

export default {
    name: "WebAppProfile",
    metaInfo() {
        return {
            meta: [
                {
                    hid: "og:title",
                    property: "og:title",
                    content: `${this.userData.displayname}`,
                    vmid: "og:title"
                },
                {
                    hid: "twitter:title",
                    name: "twitter:title",
                    content: `${this.userData.displayname}`,
                    vmid: "twitter:title"
                },
                {
                    hid: "description",
                    property: "description",
                    content: this.profileDescription,
                    vmid: "description"
                },
                {
                    hid: "og:description",
                    property: "og:description",
                    content: this.profileDescription,
                    vmid: "og:description"
                },
                {
                    hid: "twitter:description",
                    name: "twitter:description",
                    content: this.profileDescription,
                    vmid: "twitter:description"
                },
                {
                    hid: "og:image",
                    property: "og:image",
                    content: this.userProfilePicture,
                    vmid: "og:image"
                },
                {
                    hid: "twitter:image",
                    name: "twitter:image",
                    content: this.userProfilePicture,
                    vmid: "twitter:image"
                }
            ]
        }
    },
    components: {
        ProfileBox: () => import(/* webpackChunkName: "profile-box" */ "@/components/organisms/profile-box"),
        MemodListTabBar: () => import(/* webpackChunkName: "home-tab-bar" */ "@/components/organisms/memod-list-tab-bar"),
        EditProfileModal: () => import(/* webpackChunkName: "edit-profile-modal" */ "@/components/organisms/modals/edit-profile"),
        FollowingList: () => import(/* webpackChunkName: "following-list" */ "./following-list.vue"),
        FollowersList: () => import(/* webpackChunkName: "followers-list" */ "./followers-list.vue"),
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar")
    },
    data() {
        return {
            userData: {}
        };
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id,
            showFollowingList: state => state.Application.showFollowingList,
            showFollowersList: state => state.Application.showFollowersList
        }),
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        profileTabBarList() {
            return [
                {
                    name: "web-app-profile-memos",
                    label: "Memos",
                    endpoint: `/users/${this.userData.id}/feeds?`,
                    iconComponent: memosIcon
                },
                {
                    name: "web-app-profile-drafts",
                    label: "Drafts",
                    endpoint: `/users/${this.userData.id}/feeds/drafts?`,
                    isLoggedUserProfile: Number(this.userData.id) == Number(this.userId),
                    iconComponent: draftsIcon
                },
                {
                    name: "web-app-profile-boards",
                    label: "Boards",
                    endpoint: `/users/${this.userData.id}/lists`,
                    iconComponent: boardsIcon
                },
                {
                    name: "web-app-profile-liked",
                    label: "Likes",
                    endpoint: `/users/${this.userData.id}/feeds?type=liked`,
                    iconComponent: likedIcon
                }
            ]
        },
        currentListTab() {
            return this.profileTabBarList.find(tab => {
                return tab.name == this.$route.name;
            })
        },
        profileDescription() {
            return this.userData.description ? `${_truncate(this.userData.description, { "length": 150, "separator": /[^a-z0-9]? +/i })}` : "";
        },
        userProfilePicture() {
            return this.userData.photo ? this.userData.photo.url : "";
        }
    },
    watch: {
        "$route.params": {
            immediate: true,
            handler(newRoute, oldRoute) {
                if (newRoute?.displayName != oldRoute?.displayName) {
                    if (this.userData.id) {
                        this.$pusher.unsubscribe(`user-profile-${this.userData.id}`);
                    }

                    this.getUserByDisplayName(this.$route.params.displayName)
                }
            }
        }
    },
    beforeDestroy() {
        this.$pusher.unsubscribe(`user-profile-${this.userData.id}`);
    },
    methods: {
        getUserByDisplayName(displayName) {
            this.$emit("is-loading", true);
            this.userData = {};
            axios.get(`/users/${displayName}`).then(({ data }) => {
                this.userData = data;
                this.subscribeToUserProfilePusherChannel(data.id);
            }).catch((error) => {
                console.log(error.response.data.message);
            }).finally(() => {
                this.$emit("is-loading", false);
            });
        },
        subscribeToUserProfilePusherChannel(id) {
            this.$pusher.subscribe(`user-profile-${id}`).bind("followed", (data) => {
                this.userData.total_followers = data.total;
            });
        },
        toggleFollowingList(value) {
            this.$store.dispatch("Application/toggleFollowingList", value);
        },
        toggleFollowersList(value) {
            this.$store.dispatch("Application/toggleFollowersList", value);
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .following-list,
/deep/ .followers-list {
    position: absolute;
    z-index: 10;
    width: 100%;
    background-color: #0F0F10;
    height: 100vh;
}

.blocked-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    img {
        margin-bottom: 30px;
    }

    h1 {
        margin-bottom: 10px;
        font-weight: 700;
    }

    p {
        font-size: 16px;
        text-align: center;
        color: rgba(235, 235, 245, 0.6);
        line-height: 22px;
    }
}
</style>
