<template>
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="30"
            height="30"
            fill="#C4C4C4"
            fill-opacity="0.01"
        />
        <path
            d="M15 24.5002C36.2136 10.9441 22.2347 -0.0313225 15 7.40269C7.76534 -0.0313225 -6.21357 10.9441 15 24.5002Z"
            :stroke="color"
            stroke-opacity="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: "SavedIcon",
    props: {
        color: {
            type: String,
            default: "#EBEBF5"
        }
    }
};
</script>
