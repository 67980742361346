<template>
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.66675 8.33325C7.39061 8.33325 7.16675 8.55711 7.16675 8.83325C7.16675 9.10939 7.39061 9.33325 7.66675 9.33325H7.78341C8.05956 9.33325 8.28341 9.10939 8.28341 8.83325C8.28341 8.55711 8.05956 8.33325 7.78341 8.33325H7.66675ZM7.66675 14.1666C7.39061 14.1666 7.16675 14.3904 7.16675 14.6666C7.16675 14.9427 7.39061 15.1666 7.66675 15.1666H7.78341C8.05956 15.1666 8.28341 14.9427 8.28341 14.6666C8.28341 14.3904 8.05956 14.1666 7.78341 14.1666H7.66675ZM7.16675 20.4999C7.16675 20.2238 7.39061 19.9999 7.66675 19.9999H7.78341C8.05956 19.9999 8.28341 20.2238 8.28341 20.4999C8.28341 20.7761 8.05956 20.9999 7.78341 20.9999H7.66675C7.39061 20.9999 7.16675 20.7761 7.16675 20.4999ZM11.169 19.9999C10.8929 19.9999 10.669 20.2238 10.669 20.4999C10.669 20.7761 10.8929 20.9999 11.169 20.9999H22.8357C23.1118 20.9999 23.3357 20.7761 23.3357 20.4999C23.3357 20.2238 23.1118 19.9999 22.8357 19.9999H11.169ZM10.669 14.6666C10.669 14.3904 10.8929 14.1666 11.169 14.1666H22.8357C23.1118 14.1666 23.3357 14.3904 23.3357 14.6666C23.3357 14.9427 23.1118 15.1666 22.8357 15.1666H11.169C10.8929 15.1666 10.669 14.9427 10.669 14.6666ZM11.169 8.33325C10.8929 8.33325 10.669 8.55711 10.669 8.83325C10.669 9.10939 10.8929 9.33325 11.169 9.33325H22.8357C23.1118 9.33325 23.3357 9.10939 23.3357 8.83325C23.3357 8.55711 23.1118 8.33325 22.8357 8.33325H11.169Z"
            :fill="color"
            fill-opacity="0.6"
        />
        <rect
            x="4.16675"
            y="4.16675"
            width="22.1667"
            height="22.1667"
            rx="4"
            :stroke="color"
            stroke-opacity="0.6"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    name: "MemosIcon",
    props: {
        color: {
            type: String,
            default: "#EBEBF5"
        }
    }
};
</script>
