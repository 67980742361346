<template>
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.666504"
            width="30"
            height="30"
            fill="white"
            fill-opacity="0.01"
        />
        <path
            d="M24.0765 26H7.2565C5.83712 26.0111 4.67751 24.8694 4.6665 23.45L4.6665 10H26.6665V23.45C26.6555 24.8694 25.4959 26.0111 24.0765 26L24.0765 26Z"
            :stroke="color"
            stroke-opacity="0.6"
            stroke-linecap="round"
        />
        <path
            d="M6.6665 4H24.6665C25.7711 4 26.6665 4.89543 26.6665 6V10H4.6665V6C4.6665 4.89543 5.56193 4 6.6665 4H6.6665Z"
            :stroke="color"
            stroke-opacity="0.6"
            stroke-linecap="round"
        />
        <path
            d="M11.1665 15.5H20.1665"
            :stroke="color"
            stroke-opacity="0.6"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    name: "DraftsIcon",
    props: {
        color: {
            type: String,
            default: "#EBEBF5"
        }
    }
};
</script>
